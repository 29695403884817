
import { Vue, Component } from 'vue-property-decorator'
import { NavigationTabs, SearchTabs, RadioCitationFormats } from '@/components/freetools'
import { FreeToolsAPI } from '@/api/freetools-api'
import { Article, ArticlePageBibInfo } from '@/components/article-page'

@Component({
  components: {
    NavigationTabs,
    ArticlePageBibInfo,
    RadioCitationFormats,
  },
})
export default class MRLookup extends Vue {
  //
  // REACTIVE PROPERTIES
  //
  selectedTab = SearchTabs.mrLookup
  author = ''
  title = ''
  journal = ''
  firstPage = ''
  lastPage = ''
  year = ''
  // format = 'citation'
  format = 'mathscinet'
  loading = false

  all: {results: Article[], total: number} = { results: [], total: 0 }
  dml: {results: Article[], total: number} = { results: [], total: 0 }

  // formatOptions = [
  //   {
  //     text: 'MR Citation',
  //     value: 'citation',
  //   },
  //   {
  //     text: 'BibTeX',
  //     value: 'bibtex',
  //   },
  //   {
  //     text: 'AMSRefs',
  //     value: 'amsrefs',
  //   },
  //   {
  //     text: 'TeX',
  //     value: 'tex',
  //   },
  //   {
  //     text: 'EndNote',
  //     value: 'endnote',
  //   },
  // ]

  mounted() {
    document.title = 'MRLookup - MathSciNet'
  }

  //
  // COMPUTED PROPERTIES
  //
  get isSubmittable() {
    return this.author.trim().length > 0 ||
      this.title.trim().length > 0
  }

  //
  // METHODS
  //
  async onSearch(e: Event) {
    e.preventDefault()
    this.loading = true
    const result = await FreeToolsAPI.mrLookup(
      this.author,
      this.title,
      this.journal,
      this.firstPage,
      this.lastPage,
      this.year
    )

    this.all = result.all
    this.dml = result.dml
    this.loading = false
  }

  async onClear(e: Event) {
    e.preventDefault()
    this.author = ''
    this.title = ''
    this.journal = ''
    this.firstPage = ''
    this.lastPage = ''
    this.year = ''
    this.all = { results: [], total: 0 }
    this.dml = { results: [], total: 0 }

    this.format = 'citation'
  }

  formattedMRNumber(mrnumber: number) {
    // Add leading zeros if needed
    const mrNumLength = 7
    const leadingZeros = mrNumLength - mrnumber.toString().length
    return '0'.repeat(leadingZeros) + mrnumber
  }
}
